<template>
	<div>
		<LazyEmbedCalendar v-if="store.calendar" />
		<div id="smooth-wrapper">
			<div id="smooth-content">
				<slot class="view"></slot>
				<LazyAppFooter />
			</div>
		</div>
		<Background v-if="!blog" />
	</div>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

const route = useRoute();
const store = useGlobalStore()

const blog = computed(() => {
	return route.matched.some(({ path }) => path.startsWith('/insights')) ? true : false
})

useHead({
	titleTemplate: title => title ? `${title} · AMAR Design` : 'Creative Solutions You Will Love · AMAR Design',
	htmlAttrs: {
		lang: 'en'
	},
	meta: [
		{ key: 'theme-color', name: 'theme-color', content: '#fd0079' }
	],
	link: [
		{ rel: 'icon', type: 'image/png', href: '/favicon.png' },
		{ rel: 'icon', type: 'image/png', sizes: "32x32", href: '/favicon-32x32.png' },
		{ rel: 'icon', type: 'image/png', sizes: "16x16", href: '/favicon-16x16.png' }
	]
})

onMounted(() => {
	if (route.matched.some(({ path }) => path.startsWith('/insights'))) {
		document.documentElement.setAttribute('data-theme', 'light')
	} else {
		document.documentElement.setAttribute('data-theme', 'dark')
	}

	ScrollSmoother.create({
		smooth: .8,
		effects: true,
		smoothTouch: .1
	})
})

onUpdated(() => {
	if (route.matched.some(({ path }) => path.startsWith('/insights'))) {
		document.documentElement.setAttribute('data-theme', 'light')
	} else {
		document.documentElement.setAttribute('data-theme', 'dark')
	}
})
</script>